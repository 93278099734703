.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pap-yellow {
  --tw-bg-opacity: 1;
  background-color: #ffde33 !important;
}

.pap-font {
  font-family: 'Overused Grotesk', sans-serif;
}

.ta-bg-pink {
  background-color: rgb(188,32,144);
}

.ta-bg-pink:hover {
  background-color: rgba(188,32,144,0.8);
}

.ta-bg-pink-disable {
  background-color: rgba(188,32,144,0.6);
}

.iti {
  width: 100%;
}

.fill-stripe-color {
  fill: #6B71E3;
}
